//
// user-variables.scss
// Use this to override Bootstrap and Around variables
//

// Example of a variable override to change Around background color
// Remove the "//" to comment it in and see it in action!
// $body-bg: #12263F;
$primary: #B45309; // This is yellow-700
$secondary: #7283a0;
$gray-100: #F5F5F4;
$gray-200: #E7E6E4;
$gray-300: #D6D3D1;
$gray-400: #A8A29E;
$gray-500: #78716C;
$gray-600: #57534E;
$gray-700: #44403C;
$gray-800: #292524;
$gray-900: #1C1917;
$font-weight-normal:                        300;
$font-weight-medium:                        400;
$font-weight-semibold:                      500;
$font-weight-bold:                          600;
$font-weight-bolder:                        700;

// Note: We can also add additional colors to the theme in the `_variables.scss` file — see the note
// about "custom Sundial colors" for more information.
